.ant-layout-content{
    margin-left: 250px;
    margin-top: 100px;
    @media (max-width: 768px) {
        margin-left: 0;
        padding: 0 25px; 
    }
    &.hideSide{
        margin: 124px auto 0;
    }
}
.ant-progress-text{
    color: #666!important;
}
.ant-steps{
    margin-bottom: 30px!important;
}
.ant-steps-item-process,.ant-steps-item-wait{
    &> .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
        background-color: #5542F6!important;
    }
    .ant-steps-item-icon{
        background: none!important;
        border-color: #5542F6!important;
        .ant-steps-icon{
            color: #5542F6!important;
        }
    }

}
.ant-layout-header{
    height: auto !important;
    padding: 0 !important;
    // padding-left: 320px !important;

}
.ant-layout-sider {
    background-color: #FFFFFF !important;
}
.header{
    .title{
        display: inline-block;
        margin-left: 250px;
        line-height: 64px;
        font-size: 24px;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .triger{
        display: none;
        @media (max-width: 768px) {
            display: inline-block;
        }  
    }
}

.ant-menu{
    &-submenu{
        &-title{
            color: #84818A;
            cursor: auto!important;
            pointer-events: none;
        }
    }
    &-item{
        display: flex!important;
        align-items: center!important;
        svg{
            width: 20px;
            line-height: 40px;
            height: auto;
        }

        &.polygon{
            .ant-tag{
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            a{
                color: #84818A!important;
            }
        }
        a{
            display: inline-block;
            color: #000!important;
        }
        &-selected{
            &::after{
                display: none;
            }
        }
        img{
            width: 20px;
        }
    }
    &-sub.ant-menu-inline{
        background: #fff!important;
    }
}
.marketsList, .portfolioList{
    background: #fff;
    padding: 20px;
    ::-webkit-scrollbar{
        width: 7px;
        height: 5px;
        background-color: #515a6e;
        -webkit-transition: background-color .3s ease-in-out;
        transition: background-color .3s ease-in-out;
    }
    ::-webkit-scrollbar:hover{
        background-color: #d1d1d1;
    }
    ::-webkit-scrollbar-thumb{
        background-color: #5542F6;
        height: 50px;
        -webkit-border-radius:5px;
        border-radius:5px;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        -webkit-transition: background-color .3s ease-in-out;
        transition: background-color .3s ease-in-out;
    }
    ::-webkit-scrollbar-thumb:hover,
    ::-webkit-scrollbar-thumb:active{
        background-color:rgb(23, 35, 61, 0.8);
        border-right: 1px solid #f1f1f1;
        border-left: 1px solid #f1f1f1;
    }
    ::-webkit-scrollbar-track{
        background-color:#fff;
    }
    ::-webkit-scrollbar-track:hover{
        background-color:#fff;
    }
    .ant-list-items{
        width: 100%;
        height: 180px;
        padding-left: 10px;
        padding-right: 10px;
        display: -webkit-box;
            overflow-x: scroll;
            -webkit-overflow-scrolling:touch;
        .ant-list-item{
            margin: 0 10px;
            border: none;
        }
    }
    .ant-card{
        &-extra{
            padding: 0;
            font-size: 10px;
            b{
                &.up{
                    color: #03CE3C;
                }
                &.down{
                    color: #F64242;
                }
            }
        }
        &-head{
            padding: 0; 
            border-bottom: none;
            &-title{
                padding: 0;
                font-size: 10px;
                b{
                    font-size: 10px; 
                }
            }
        }
        &-body{
            padding: 0;
        }
    }
}
.marketsList{
    .ant-card{
        padding: 8px;
        width: 158px;
        &-head-title{
            img{ background: #ddd; border-radius: 50%;}
        }
    }
}
.portfolioList{
    .ant-card{
        &-head{
            min-height: 30px;
        }
        &-body{
            padding: 24px;
        }
        &-grid{
            padding: 0;
            white-space: nowrap; 
            width: 100%; 
            overflow: hidden;
            text-overflow:ellipsis;
        }
        padding: 20px 30px;
        width: 268px;

    }
}
.marketTabs{
    margin-top: 80px!important;
}
.searchInput{
    .ant-input{
        width: 520px!important;
        @media (max-width: 1300px) {
            width: 200px!important;
        }
    }
}
.marketTable{
    .ant-table-thead > tr > th{
        background: #fff;
        font-size: 12px;
        color: #84818A;
        font-weight: 400;
    }
    .ant-table-tbody > tr{
        cursor: pointer;
    }
    .ant-table-tbody > tr > td{
        font-size: 16px;
    }
    .ant-input-group-addon{
        cursor: pointer;
    }
    .ant-slider{
        &-track{
            background: #5542F6;
        }
        &-handle{
            border: 0.5px solid #EBEAED;
            box-shadow: 1px 1px 5px rgba(85, 66, 246, 0.5); 
        }
        &-disabled{
            .ant-slider-track{
                background-color: #5542F6!important;
            }
            .ant-slider-handle{
                border: 0.5px solid #EBEAED!important;
                box-shadow: 1px 1px 5px rgba(85, 66, 246, 0.5)!important;
            }
        }
    }
    .ant-form-item{
        margin-bottom: 0;
        &-explain{
            font-size: 10px!important;
        }
    }
    .percentInput{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .ant-form-item-explain{
            display: none;
            text-align: left;
        }
        .ant-form-item-explain-error{
            display: block;
        }
    }
    .ant-progress-circle .ant-progress-text{
        font-size: 10px;
    }
}
.portfolio-page-header{
    padding: 0!important;
    // .ant-col{
    //     // &:not(:first-of-type){
    //     //     // margin-top: 30px;
    //     // }
    // }
    .chartCard{
        background: #fff;
        height: 300px;
        padding: 20px 30px!important; 
        .ant-card{
            b{
            }
            &-extra{
                padding: 0;
                font-size: 10px;
            }
            &-head{
                padding: 0!important; 
                border-bottom: none;
                &-title{
                    padding: 0;
                    font-size: 10px;
                    line-height: 30px;
                    color: #84818A;
                    b{
                        font-size: 10px; 
                    }
                    .price{
                        font-size: 16px;
                        color: #5542F6;
                        font-weight: 600;
                        margin-right: 15px;
                    }
                    .up{
                        color: #03CE3C;
                    }
                    .down{
                        color: #F64242;
                    }
                }
            }
            &-body{
                padding: 0!important;
            }
        }
    }
    .infoCard{
        .ant-card-head{
            border-bottom: none;
            a{
                text-decoration: none;
                line-height: 20px;
            }
        }
        .ant-card-body{
            padding: 24px!important;
        }
        .ant-card-grid{
            padding: 0;
        }
        &:last-of-type{
            margin-top: 18px;
        }
        p{
            color: #84818A;
            font-size: 12px;
            margin-bottom: 0;
        }
        b{
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            display: block;
        }
        a{
            text-decoration: underline;
        }
    }
}
.form{
    .ant-form-item-label > label{
        display: block!important;
        p{
            margin-bottom: 0;
        }
    }
}
.tokenAvator{
    width: 20px;
    height: 20px;
    background: #ddd;
    border-radius: 50%;
    margin-right: 10px;
}
.tokenList{
    list-style: none;
    padding: 0;
    &>li{
        display: inline-block;
        &:not(:last-of-type){
            padding-right: 30px;
        }
    }
}
.ant-table-expanded-row-fixed{
    width: 100%!important;
}
.expandList{
    list-style: none;
    width: 100%;
    padding-left: 35px;
    &>li{
        display: inline-block;
        padding: 0 16px;
        &:first-of-type{
            width: 100px;
        }
        &:nth-of-type(2){
            width: 150px;
        }
        &:nth-of-type(3){
            width: 160px;
        }
        &:nth-of-type(4){
            width: 150px;
        }
    }
}
.hoverBtn{
    display: inline-block;
    position: relative;
    .ant-btn{
        margin: 0 10px;
    }
    .top{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-btn{
            display: block;
        }
    }
}
.waveModal{
    .ant-form-item-label{
        text-align: left;
    }
}
@primary-color: #5542F6;